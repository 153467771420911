.routes {
  min-height: 100%;
  display: grid;
  grid-template: "main";
  flex: 1;
  position: relative;
  overflow: hidden;
}

.routes > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  flex: 1 1 auto;
  position: relative;
  min-height: 100vh;
  opacity: 1;
  transition: opacity 100ms linear;
}

.route-exit-active {

  opacity: 1;
}

.route-exit {
  opacity: 0;
}

.route-enter-active {
  opacity: 0;
}

.route-enter {
  opacity: 1;
}