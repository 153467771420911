.react-tel-input .form-control { 
  padding: 9px 14px 9px 58px; 
  width: 100% !important; 
  height: 40px; 
  border-radius: 4px;
  background: transparent;
}
.react-tel-input .special-label { 
  left: 10px;
  top: -8px;
  color: #00000099;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em; 
}
/* LIGHT */
 .react-tel-input:hover  .special-label {
  color: #3057E4;
}

 .react-tel-input .form-control:hover {
  border-color: #3057E4;
}



/* DARK */
.dark .react-tel-input .form-control {
  color: #ffffff;
  border-color: #5b6071;
}

.dark .react-tel-input:hover  .special-label {
  color: #68D8EA;
}

.dark .react-tel-input .form-control:hover {
  border-color: #68D8EA;
}

.dark .react-tel-input .form-control:focus {
  border-color: #68D8EA;
  box-shadow: 0 0 0 1px #68D8EA;
}

.dark .react-tel-input .special-label {
  color: #FFFFFF2B;
  background: #212840;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}


.dark .react-tel-input .country-list {
  background: #212840;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  border-radius: 4px;
}

.dark .react-tel-input .country-list .country.highlight,
.dark .react-tel-input .country-list .country:hover  {
  background-color: #68D8EA1A;
}

.dark .react-tel-input .country-list .country .dial-code {
  color: #FFFFFFB2;
}

.dark .react-tel-input .selected-flag .arrow {
  border-top-color: #fff;
}

.error.dark .react-tel-input .form-control {
  box-shadow: none;
  border-color:#FF5A6A;
}

.error .react-tel-input .form-control {
  box-shadow: none;
  border-color:#FF5A6A;
}

.error.react-tel-input > .special-label {
  color: #B40516!important;
  font-weight: 700;
}

.error.react-tel-input:hover>.special-label {
  color: #820411 !important;
}

.error.react-tel-input > input {
  border-color: #B40516 !important;
}

.error.react-tel-input>input:focus {
  border-color: #B40516 !important;
  box-shadow: 0 0 0 1px #820411 !important;
}

.error.react-tel-input:hover>input {
  border-color: #820411 !important;
  box-shadow: 0 0 0 1px #820411 !important;
}